import { makeAutoObservable } from 'mobx'

import { API } from '../api'

import RootStore from './RootStore'
import { HouseSpecialElems, HouseSpecialElemType } from './statics'

class UIStore {
  private rootStore: RootStore
  public api: API
  public houseSVG: string
  public cardExpandedM: boolean = false

  // public onlyLanding: boolean = true
  public showProductCard: boolean = false
  public showFollowingContentCard: boolean = false
  public showContactCard: boolean = false
  public specialElemsInHouse: HouseSpecialElemType[] = HouseSpecialElems

  public allImagesInModulesLoaded: boolean = false
  public mobileContentExpanded: boolean = false

  public isFrontSide: boolean = true

  public contentTypeOnBackSide: 'menu' | 'followingContent' | 'contact' = 'menu'
  // There is no variable for contentTypeOnFrontSide because frontSide is always 'product'

  public contactCardTrigger: 'product' | 'menu' | 'none' = 'none'
  // from where is contact card triggered

  public emailState: 'succeeded' | 'failed' | '' = ''

  public constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.api = new API()
    this.rootStore = rootStore
    this.loadHouseSVG()
    this.emailState = ''
  }

  loadHouseSVG() {
    return new Promise((resolve) => {
      this.api
        .getHouseSVG()
        .then((result) => {
          this.updateHouseSVG(result)
          resolve(result)
        })
        .catch((error) => console.log(error))
    })
  }

  updateHouseSVG = (svg: string) => {
    this.houseSVG = svg
  }

  updateCardExpandedM = (val: boolean) => {
    this.cardExpandedM = val
  }

  // updateOnlyLanding = (val: boolean) => {
  //   this.onlyLanding = val
  // }

  updateShowProductCard = (val: boolean) => {
    this.showProductCard = val
  }

  updateShowFollowingContentCard = (val: boolean) => {
    this.showFollowingContentCard = val
  }

  updateShowContactCard = (val: boolean) => {
    this.showContactCard = val
  }

  // toggleVisibleById = (viewBool: boolean, id: string) => {
  //   console.log('id: ', id)
  //   if (id !== 'none') {
  //     const specialSVGElementsChange = specialSVGElements
  //     for (const element of specialSVGElementsChange) {
  //       if (element.id === id) {
  //         element.view = viewBool
  //       }
  //     }
  //     setSpecialSVGElements(specialSVGElementsChange)
  //   }
  // }

  updateSpecialElemsInHouse = (id: string, viewBool: boolean) => {
    if (id !== 'none') {
      const specialSVGElementsChange = this.specialElemsInHouse
      for (const element of specialSVGElementsChange) {
        if (element.id === id) {
          element.view = viewBool
        }
      }
      this.specialElemsInHouse = specialSVGElementsChange
    }
  }

  updateAllImagesInModulesLoaded = (loaded: boolean) => {
    this.allImagesInModulesLoaded = loaded
  }

  updateMobileContentExpanded = (expanded: boolean) => {
    this.mobileContentExpanded = expanded
  }

  updateIsFrontSide = (flipped: boolean) => {
    this.isFrontSide = flipped
  }

  updateContentTypeOnBackSide = (type: 'menu' | 'followingContent' | 'contact') => {
    this.contentTypeOnBackSide = type
  }

  updateContactCardTrigger = (trigger: 'menu' | 'product' | 'none') => {
    this.contactCardTrigger = trigger
  }

  handleFlipCard = (
    isFrontSide: boolean,
    contentType?: 'menu' | 'followingContent' | 'contact',
    from?: 'product' | 'menu'
  ) => {
    console.log('flip: ', isFrontSide)
    //control flipping
    this.updateIsFrontSide(isFrontSide)

    // set the contentType of back side, only back side has type, cuz front-side has only product
    if (!isFrontSide) {
      this.updateContentTypeOnBackSide(contentType)
    }

    if (!!from) {
      switch (from) {
        case 'product':
          break
        case 'menu':
        default:
          break
      }
    }
  }

  get onlyLandingVisible() {
    return !this.showContactCard && !this.showFollowingContentCard && !this.showProductCard
  }
}

export default UIStore
