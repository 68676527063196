import {
  Button,
  Divider,
  Image,
  SanitizedContent,
  Typography,
  TypographyType
} from 'components/atoms'
import { CollapsibleParagraph } from 'components/molecules'
import { useStore } from 'context/index'
import { observer } from 'mobx-react-lite'
import React, { ReactElement } from 'react'
import { ReactNode } from 'react'
import ReactPlayer from 'react-player'
import { AppTheme as theme } from 'theme/index'

import { AtomLink } from './atomLink'
import { ISubModule, ISubModuleAtom, TModuleId, TSubModuleId } from './statics'
import { BlockContainer } from './styles'

interface ISubModuleProps {
  moduleId: TModuleId
  subModule: ISubModule
  kind?: string
  isParentOn?: boolean
  handleShowContent?(): void
  handleImageLoad?(): void
  // allContentLoaded?: boolean
}
export const SubModule: React.FC<ISubModuleProps> = observer(
  ({
    moduleId,
    subModule,
    kind,
    isParentOn,
    handleShowContent,
    handleImageLoad
  }: ISubModuleProps) => {
    const { appStore } = useStore()

    const dompurifyOptions = {
      ADD_ATTR: ['target']
    }

    const renderSubModuleAtom = (
      atom: ISubModuleAtom,
      atomIndex: number,
      subModuleId: TSubModuleId,
      moduleId: TModuleId
    ): ReactElement | ReactNode => {
      const marginBottom =
        !!atom.page?.marginBottom && atom.page.marginBottom === 'none' ? 'none' : 'S'

      switch (atom.page.type) {
        case 'textP':
          return (
            <>
              {kind === 'followUpContent' ? (
                <BlockContainer
                  key={atomIndex}
                  mb={marginBottom === 'S' ? theme.space.S : theme.space.NONE}
                  data-ref='textPBlockContainer'>
                  <Typography type={TypographyType.TEXT}>
                    <SanitizedContent content={atom.val} options={dompurifyOptions} hasSpace />
                  </Typography>
                </BlockContainer>
              ) : (
                <BlockContainer key={atomIndex} mb={theme.space.S} data-ref='textPBlockContainer'>
                  <CollapsibleParagraph>{atom.val}</CollapsibleParagraph>
                </BlockContainer>
              )}
            </>
          )

        case 'textH2':
          return (
            <BlockContainer key={atomIndex}>
              <Typography type={TypographyType.CATEGORY}>{atom.val}</Typography>
              <Divider color='primary' marginTop={theme.space.XXS} marginBottom='0' />
            </BlockContainer>
          )

        case 'textH3':
          return (
            <BlockContainer key={atomIndex} mb={theme.space.S}>
              <Typography type={TypographyType.SUBHEAD} inline={false}>
                {atom.val}
              </Typography>
            </BlockContainer>
          )

        case 'textPoint':
          return (
            <BlockContainer key={atomIndex} mb={theme.space.XS}>
              <Typography type={TypographyType.TEXT} inline={false}>
                {`Förderart: ${atom.val}`}
              </Typography>
            </BlockContainer>
          )

        case 'vimeoID':
          return (
            <BlockContainer key={atomIndex} mb={theme.space.S}>
              <a
                href={'https://player.vimeo.com/video/' + atom.val}
                target='_blank'
                style={{
                  textDecoration: 'none'
                }}
                rel='noreferrer'>
                <Button fullWidth>Video öffnen</Button>
              </a>
            </BlockContainer>
          )

        case 'image':
          // console.log(atom.page.type, atom.page.subType, atom.val, moduleId)
          if (!!atom.page.subType && atom.page.subType === 'withFallbackImage') {
            if (atom.val === '') {
              atom.val = atom.page.fallBackImage
              // TODO: what should be displayed if there is no image-url, /uploads/flyerIcon.svg ?
              // return (
              //   <Image
              //     key={atomIndex}
              //     url={appStore.makeFullUrl(atom.val)}
              //     margin={`0px 0px ${theme.space.M}px`}
              //     onLoad={handleImageLoad}
              //   />
              // )
            } else {
              return (
                <Image
                  key={atomIndex}
                  url={appStore.makeFullUrl(atom.val)}
                  margin={`0px 0px ${theme.space.M}px`}
                  onLoad={handleImageLoad}
                />
              )
            }
          }
          if (moduleId === 'CONTENT' && atom.val !== '') {
            // folgeinhalt
            return (
              <Image
                key={atomIndex}
                url={appStore.makeFullUrl(atom.val)}
                margin={`4px 0px ${theme.space.XL}px`}
                maxHeight='500px'
                onLoad={handleImageLoad}
              />
            )
          } else if (moduleId === 'MATERIAL') {
            return <></>
          } else if (moduleId === 'PRODUCT' && atom.val !== '') {
            return (
              <Image
                key={atomIndex}
                url={appStore.makeFullUrl(atom.val)}
                margin={`0px 0px ${theme.space.M}px`}
                onLoad={handleImageLoad}
              />
            )
          } else {
            if (!!atom.val && atom.val !== '') {
              return (
                <Image
                  key={atomIndex}
                  url={appStore.makeFullUrl(atom.val)}
                  margin={`0px 0px ${theme.space.M}px`}
                  onLoad={handleImageLoad}
                />
              )
            } else {
              return <></>
            }
          }

        case 'file':
          return (
            <BlockContainer
              key={atomIndex}
              mb={marginBottom === 'S' ? theme.space.S : theme.space.NONE}>
              <AtomLink
                subModule={subModule}
                linkIndexStart={atomIndex}
                handleShowContent={handleShowContent}
              />
            </BlockContainer>
          )

        case 'videoupload':
          return (
            // https://github.com/cookpete/react-player/issues/1112
            <BlockContainer key={atomIndex} mb={theme.space.S}>
              <ReactPlayer
                controls
                url={appStore.makeFullUrl(atom.val)}
                playing={isParentOn}
                width='100%'
                height='auto'
              />
            </BlockContainer>
          )

        case 'linkText':
        case 'linkText1':
        case 'linkImage':
          if (subModuleId === 'LINKEXTERN') {
            return (
              <BlockContainer key={atomIndex} mb={theme.space.NONE}>
                <AtomLink
                  subModule={subModule}
                  linkIndexStart={atomIndex}
                  handleShowContent={handleShowContent}
                />
              </BlockContainer>
            )
          } else if (subModuleId === 'CLASSICWITHINTERNLINK') {
            return (
              <BlockContainer key={atomIndex} mb={theme.space.NONE} mt={theme.space.XL}>
                <AtomLink
                  subModule={subModule}
                  linkIndexStart={atomIndex}
                  handleShowContent={handleShowContent}
                />
              </BlockContainer>
            )
          } else if (subModuleId === 'SUBDETAIL2B') {
            return (
              <BlockContainer
                key={atomIndex}
                mb={marginBottom === 'S' ? theme.space.S : theme.space.NONE}>
                <AtomLink
                  subModule={subModule}
                  linkIndexStart={atomIndex}
                  handleShowContent={handleShowContent}
                />
              </BlockContainer>
            )
          } else {
            return (
              <BlockContainer
                key={atomIndex}
                mt={theme.space.XL}
                mb={marginBottom === 'S' ? theme.space.S : theme.space.NONE}>
                <AtomLink
                  subModule={subModule}
                  linkIndexStart={atomIndex}
                  handleShowContent={handleShowContent}
                />
              </BlockContainer>
            )
          }

        default:
          return <></>
      }
    }

    const marginBottomSubModule = !!subModule.meta?.page?.marginBottom
      ? subModule.meta.page.marginBottom
      : 'XL'

    return (
      <BlockContainer
        mb={
          marginBottomSubModule === 'none'
            ? theme.space.NONE
            : subModule.meta.global.id === 'MODULEHEAD'
            ? theme.space.S
            : theme.space.XXXL
        }
        data-ref={`BlockContainerSubModule-${subModule.meta.global.id}`}>
        {subModule.atoms.map((atom: ISubModuleAtom, index: number) => (
          <React.Fragment key={index}>
            {renderSubModuleAtom(atom, index, subModule.meta.global.id, moduleId)}
          </React.Fragment>
        ))}
      </BlockContainer>
    )
  }
)
