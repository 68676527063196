import { readableColor } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { AppTheme as theme } from 'theme/index'

import { Typography, TypographyType } from '../Typography'

interface CheckboxProps extends CheckBoxContainerProps, BoxProps {
  name: string
  value?: string
  onChangeCheckState?(): void
  children?: string | React.ReactNode
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  name,
  value,
  onChangeCheckState,
  checked,
  hideBox,
  children
}) => {
  const [isChecked, setIsChecked] = React.useState(checked)

  const handleCheckStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked)
    if (onChangeCheckState) {
      onChangeCheckState()
    }
  }

  const handleLabelClick = () => {
    if (onChangeCheckState) {
      onChangeCheckState()
    }
    setIsChecked(!isChecked)
  }

  return hideBox ? (
    <CheckboxContainer
      className='checkbox'
      margin={theme.space.XS}
      paddingY={theme.space.M}
      paddingX={theme.space.L}
      fontWeight={theme.fontweight.light}
      checked={checked}
      bg={checked ? 'primary' : 'white'}>
      <StyledCheckBox
        type='checkbox'
        name={name}
        value={value}
        onChange={onChangeCheckState}
        hideBox={true}
      />
      <Typography
        type={TypographyType.TEXT}
        color={checked ? readableColor(theme.colors.primary, '#000', '#fff') : 'gray'}>
        {name}
      </Typography>
    </CheckboxContainer>
  ) : (
    <RegularCheckboxContainer
      paddingY={theme.space.M}
      className='checkbox'
      onClick={handleLabelClick}>
      <StyledCheckBox
        type='checkbox'
        name={name}
        onChange={(event) => handleCheckStateChange(event)}
        hideBox={false}
        checked={isChecked}
      />
      <StlyedLabel paddingX={theme.space.S}>{children}</StlyedLabel>
    </RegularCheckboxContainer>
  )
}

Checkbox.defaultProps = {
  checked: false
}

export default Checkbox

interface CheckBoxContainerProps {
  checked?: boolean
}

const CheckboxContainer = styled.label<
  SpaceProps & TypographyProps & ColorProps & CheckBoxContainerProps
>`
  ${space}
  ${typography}
  ${color}

  display: inline-block;
  border: solid 1px #eee;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: ${(props) => props.checked && 'inset 0 0 4px rgba(0, 0, 0, 0.2)'};
  transition: all 0.3s ease;
`

const RegularCheckboxContainer = styled.div<SpaceProps>`
  ${space}
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  cursor: pointer;

  :hover {
    cursor: pointer;
  }
`

interface BoxProps {
  hideBox: boolean
}

const StyledCheckBox = styled.input<BoxProps>`
  /* display: none; */
  display: ${(props) => (props.hideBox ? 'none' : 'inlinek-box')};
  cursor: pointer;
`

interface LabelProps {
  for?: string
}

const StlyedLabel = styled.label<SpaceProps & LabelProps>`
  ${space}
  display: inline;
  cursor: pointer;
`
