import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps } from 'styled-system'

import { AppTheme as theme } from '.'

interface ContentSizeWrapperMobileScrollProps {
  showAll?: boolean
}

export const FlexWrapperMobileHeadAndScroll = styled.div<
  ContentSizeWrapperMobileScrollProps & SpaceProps
>`
  height: ${(props) =>
    props.showAll
      ? `${props.theme.WindowInnerHeight - 118}px`
      : `calc(${props.theme.WindowInnerHeight - theme.space.L}px - 50vh)`};
  transition: all 0.6s ease;
  ${space}
  width: 100%;
`
export const FlexWrapperMobileHeadAndScrollWithoutHeader = styled.div<
  ContentSizeWrapperMobileScrollProps & SpaceProps
>`
  height: ${(props) =>
    props.showAll
      ? `calc(100vh - 106px - ${theme.canvas.vertical})`
      : `calc(70vh - 106px - ${theme.canvas.vertical})`};
  transition: all 0.6s ease;
  ${space}
`

export const ContentSizeWrapperMobileScroll = styled.div<ContentSizeWrapperMobileScrollProps>`
  height: ${(props) =>
    props.showAll
      ? `calc(92vh - ${theme.canvas.vertical})`
      : `calc(62vh - ${theme.canvas.vertical})`};
  transition: all 0.6s ease;
`

export const FlexScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
export const FlexScrollContainerHead = styled.div<SpaceProps>`
  ${space}
`

export const FlexScrollContainerBody = styled.div<SpaceProps>`
  ${space}
  /* flex: 1 1 auto; */
  overflow: scroll;
  height: 100%;
  width: ${`calc(100% + ${theme.space.XXL}px)`};
  -webkit-overflow-scrolling: touch;
`

export const FlexScrollContainerBodyInner = styled.div`
  /* height: 100%; */
  /* -webkit-overflow-scrolling: touch; */
  padding-right: ${(props) => props.theme.space.XXL}px;
`
export const MobileExpandBtnContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

interface ContentCardProps {
  isOn?: boolean
  setWidth: string
}

export const StyledContainer = styled.div<ColorProps & ContentCardProps>`
  display: inline-block;
  height: ${(props) => props.theme.WindowInnerHeight}px;
  width: ${(props) => (props.setWidth === 'big' ? '70vw' : '30vw')};
  transform: translateX(${(props) => (props.isOn ? `-70vw` : '0')});
  transition: transform 0.6s ease;
  ${color}
`
