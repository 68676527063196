import { Link, Typography, TypographyType } from 'components/atoms'
import { useStore } from 'context/index'
import React from 'react'
import styled from 'styled-components'

export const LinkFooter: React.FC = () => {
  const { appStore } = useStore()

  return (
    <FooterContainer>
      {!!appStore.EHSettings?.settings_sw_text_form_data_imprint_url?.question && (
        <div style={{ marginRight: '15px' }}>
          <Link
            href={appStore.EHSettings?.settings_sw_text_form_data_imprint_url?.question}
            noDecoration
            target='_blank'>
            <Typography type={TypographyType.TEXT_SMALL}>Impressum</Typography>
          </Link>
        </div>
      )}

      {!!appStore.EHSettings?.settings_sw_text_form_data_protection_url?.question && (
        <div>
          <Link
            href={appStore.EHSettings?.settings_sw_text_form_data_protection_url?.question}
            noDecoration
            target='_blank'>
            <Typography type={TypographyType.TEXT_SMALL}>Datenschutz</Typography>
          </Link>
        </div>
      )}
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export default LinkFooter
