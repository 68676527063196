import { useStore } from 'context/index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { StyledContainer } from 'theme/index'

import ContentCard from './component'

export const FollowingContent: React.VFC = observer(() => {
  const { contentStore, uiStore } = useStore()

  const handleCloseContent = () => {
    uiStore.updateShowFollowingContentCard(false)
    contentStore.updateFollowUpContent(undefined, false)
  }

  return (
    <StyledContainer
      setWidth='big'
      bg='primary'
      className='content-card'
      isOn={uiStore.showFollowingContentCard}>
      {Boolean(contentStore.followUpContent) &&
        contentStore.followUpContent.modules?.length > 0 && (
          <ContentCard
            isOn={uiStore.showFollowingContentCard}
            content={contentStore.followUpContent}
            onClose={handleCloseContent}
            loaded={contentStore.followUpContentLoaded}
          />
        )}
    </StyledContainer>
  )
})
