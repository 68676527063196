import { faExclamation, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DefaultIcon,
  IconButton,
  IconType,
  SVGIcon,
  Typography,
  TypographyAlignment,
  TypographyType
} from 'components/atoms'
import { Card } from 'components/molecules'
import { ContactCard, LandingCard, ProductCard } from 'components/templates'
import { FollowingContent } from 'components/templates'
import { useStore } from 'context/index'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { StyledContainer, AppTheme as theme } from 'theme/index'

interface ContentPageTabletProps {
  title?: string
}

export const ContentPageTablet: React.FunctionComponent<ContentPageTabletProps> = observer(
  ({ title }: ContentPageTabletProps) => {
    const { contentStore, uiStore, appStore } = useStore()

    const phone = appStore.CISettings?.settings_sw_text_sw_phone
    const email = appStore.CISettings?.settings_sw_text_sw_email

    // const [followingContent, setFollowingContent] = React.useState({} as any)
    const [isDialogOn, setIsDialogOn] = React.useState(false)

    useEffect(() => {
      if (uiStore.emailState !== '') {
        setTimeout(() => {
          setIsDialogOn(false)
        }, 3000)
      }
    })

    const handleCloseProductCard = () => {
      uiStore.updateShowProductCard(false)
      uiStore.updateShowFollowingContentCard(false)
      setTimeout(() => {
        contentStore.updateActualProductId('')
        contentStore.updateSelectedProductForBtn(undefined)
        // setProduct(noProduct)
      }, 600) //hide after close animation

      // clear all selected product
      // setSelectedProductCancelled(true)
      // setClearProductFromMenu(true)
    }

    const toggleContactCard = (state: boolean) => {
      uiStore.updateShowContactCard(state)
      // if (uiStore.showContactCard) {
      //   uiStore.updateOnlyLanding(false)
      // } else {
      //   uiStore.updateOnlyLanding(true)
      // }
    }

    const openFollowingContentCard = () => {
      contentStore.loadFollowUpContent()
      uiStore.updateShowFollowingContentCard(true)
    }

    const closeFollowingContentCard = () => {
      uiStore.updateShowFollowingContentCard(false)
    }

    const handleSendEmailFeekback = () => {
      setIsDialogOn(true)
      uiStore.updateShowContactCard(false)
      uiStore.updateShowProductCard(false)
    }

    const shoudRenderDefaultIcon = (name: string | IconType) => {
      return (
        name === IconType.Default1 ||
        name === IconType.Default2 ||
        name === IconType.Default3 ||
        name === IconType.Default4 ||
        name === IconType.Default5 ||
        name === IconType.Default6
      )
    }

    const handleCloseContactCard = () => {
      contentStore.updateSelectedProductForBtn(undefined)
      uiStore.updateShowContactCard(false)
      uiStore.updateShowProductCard(false)
    }

    return (
      <TabletContainer className='tablet-container'>
        {/* ============ Landing Card ============= */}
        <LandingCard
          isExpanded={uiStore.onlyLandingVisible}
          hidden={uiStore.showFollowingContentCard}
          title={title}
        />

        {/* ============ Icon-Group on the right side ============= */}
        <SideLabelContainer
          rightPosition={
            uiStore.onlyLandingVisible ? 'none' : uiStore.showFollowingContentCard ? 'big' : 'small'
          }>
          <IconButton
            isLabel
            color='secondary'
            onClick={() => toggleContactCard(!uiStore.showContactCard)}
            zIndex={uiStore.showContactCard ? 10 : 0}>
            <SVGIcon
              iconName={IconType.IconContact}
              viewBox='0 0 42 42'
              color={theme.colors.primary}
              width='42px'
              height='42px'
              margin='0px 0px 0px 0px '
            />
          </IconButton>

          <IconButton
            isLabel
            color='primary'
            onClick={() => uiStore.updateShowContactCard(false)}
            visible={!!contentStore.selectedProductForBtn} //toDo!
            zIndex={!uiStore.showContactCard ? 10 : 0}
            margin='10px 0 0 0'>
            {!!contentStore.selectedProductForBtn &&
            !!contentStore.selectedProductForBtn.icon &&
            shoudRenderDefaultIcon(contentStore.selectedProductForBtn.icon) ? (
              <DefaultIcon
                iconName={contentStore?.selectedProductForBtn?.icon ?? ''}
                width='30px'
                height='30px'
                color={theme.colors.primary}
              />
            ) : (
              <SVGIcon
                iconName={contentStore?.selectedProductForBtn?.icon ?? ''}
                viewBox='0 0 42 42'
                color={
                  contentStore?.selectedProductForBtn?.specialProp?.iconOptic === 'promotion'
                    ? theme.colors.green
                    : theme.colors.primary
                }
                width='42px'
                height='42px'
                margin='0px 0px 0px 0px '
              />
            )}
          </IconButton>
        </SideLabelContainer>

        {/* ============ Product Card / Contact Card ============= */}
        {!uiStore.onlyLandingVisible && (
          <>
            {uiStore.showContactCard ? (
              <StyledContainer setWidth='small' bg='secondary' className='contact-card'>
                <ProductContainer>
                  <Card
                    overflow='auto'
                    padding={`${theme.space.M}px ${theme.space.XXL}px ${theme.space.L}px`}>
                    <ContactCard
                      emailSent={handleSendEmailFeekback}
                      onCloseContact={handleCloseContactCard}
                    />
                  </Card>
                </ProductContainer>
              </StyledContainer>
            ) : (
              <ProductCard
                onClose={handleCloseProductCard}
                onShowFollowingContent={openFollowingContentCard}
                onCloseFollowingContent={closeFollowingContentCard}
                showFollowingContent={uiStore.showFollowingContentCard}
                product={contentStore.actualProductInfo}
              />
            )}

            {/* ============ FollowingContent Card ============= */}
            <FollowingContent />
            {/* <StyledContainer
              setWidth='big'
              bg='primary'
              className='content-card'
              isOn={uiStore.showFollowingContentCard}>
              {followingContent.modules?.length > 0 && (
                <ContentCard
                  isOn={uiStore.showFollowingContentCard}
                  content={followingContent}
                  onClose={closeFollowingContentCard}
                />
              )}
            </StyledContainer> */}
          </>
        )}

        {/* ============ Dialog for Email-Feedback ============= */}
        <StyledDialog isOn={isDialogOn} className='email_feedback'>
          <FontAwesomeIcon
            icon={uiStore.emailState === 'failed' ? faExclamation : faPaperPlane}
            size='2x'
            color={uiStore.emailState === 'failed' ? 'd00000' : theme.colors.primary}
          />
          <Typography
            type={TypographyType.PRODUCT_HEAD}
            alignment={TypographyAlignment.CENTER}
            fontWeight={300}>
            {uiStore.emailState === ''
              ? 'Ihre Nachricht wird gesendet.'
              : uiStore.emailState === 'succeeded'
              ? 'Ihre Nachricht wurde erfolgreich versendet!'
              : `Oops, etwas ist schief gelaufen. \nBitte versuchen Sie es nochmal oder kontaktieren Sie uns unter ${phone} oder ${email}. \nVielen Dank!`}
          </Typography>
        </StyledDialog>
      </TabletContainer>
    )
  }
)

export default ContentPageTablet

const ProductContainer = styled.div`
  margin-top: 40px;
  height: calc(100% - 40px);
`

const TabletContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: ${(props) => props.theme.WindowInnerHeight}px;
  width: 200vw;
  /* .appIsInModal & {
    width: calc(200vw - 40px);
  } */
`

interface SideLabelProps {
  rightPosition?: 'none' | 'big' | 'small'
}

const SideLabelContainer = styled.div<SideLabelProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: absolute;
  height: auto;
  top: 60px;
  /* appIsInModalIsNotApplied (margin-right mit right ersetzen funktioniert) */
  right: ${(props) =>
    props.rightPosition === 'none' ? '0px' : props.rightPosition === 'big' ? '100vw' : '30vw'};

  transition: right 0.5s ease;
`

interface DialogProps {
  isOn: boolean
}

const StyledDialog = styled.div<DialogProps>`
  position: absolute;
  width: 600px;
  height: 300px;
  padding: 25px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  visibility: ${(props) => (props.isOn ? 'visible' : 'hidden')};
  transition: visibility 500ms ease;
  z-index: 20000;
  font-size: 22px;
  animation: ${(props) => (props.isOn ? fadeIn : fadeOut)} 600ms ease 1;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 5px rgba(0, 0, 0, 0.3);
  font-family: ${(props) => props.theme.fontFamily};
`

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`
const fadeOut = keyframes`
  0% { opacity: 1; }
  70% { opacity: 0; }
  100% { opacity: 0; }
`
