import { IFormValues } from 'components/templates'
import React from 'react'
import { FieldError, Path, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

interface InputFieldProps {
  label: Path<IFormValues>
  register: UseFormRegister<IFormValues>
  required?: boolean
  error?: FieldError | undefined
  placeholder?: string
}

export const TextField: React.VFC<InputFieldProps> = ({
  label,
  register,
  required = false,
  placeholder,
  error
}) => {
  const placeholderText = required ? `${placeholder}*` : placeholder

  return (
    <TextFieldContainer>
      <StyledInputField
        {...register(label, {
          required: {
            value: required,
            message: 'Pflichtfeld'
          },
          pattern: {
            value: label === 'email' ? /\S+@\S+\.\S+/ : /[\s\S]*/,
            message: 'Email nicht gültig'
          }
        })}
        placeholder={placeholderText}
        hasError={Boolean(error)}
      />
      {Boolean(error) && <HintText>{error?.message}</HintText>}
    </TextFieldContainer>
  )
}

const TextFieldContainer = styled.div`
  width: 100%;
  position: relative;
`

const StyledInputField = styled.input<SpaceProps & { hasError: boolean }>`
  ${space}
  width: 100%;
  height: 40px;
  font-weight: ${(props) => props.theme.fontweight.light};
  font-size: 16px;
  outline: none;
  color: ${(props) => props.theme.colors.darkgray};
  border: ${(props) => (props.hasError ? 'solid 1px #f44336' : 'solid 1px #eee')};
  padding: ${(props) => props.theme.space.M}px;

  :focus {
    outline: none;
    outline-width: 0;
    background-color: white;
    border: ${(props) => (props.hasError ? 'solid 1px #f44336' : 'solid 1px #eee')};
  }

  ::placeholder {
    font-weight: lighter;
    color: ${(props) => props.theme.colors.gray};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 16px;
  }
`

const HintText = styled.div`
  font-size: 11px;
  position: absolute;
  color: red;
  top: 5px;
  right: 5px;
  font-family: ${(props) => props.theme.fontFamily};
`
