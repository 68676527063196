import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from 'components/atoms'
import { Card, MenuList, ProductCardHead } from 'components/molecules'
import { IProductForBtn } from 'components/statics'
import React from 'react'
import {
  FlexScrollContainer,
  FlexScrollContainerBody,
  FlexScrollContainerHead,
  AppTheme as theme
} from 'theme/index'

interface MenuProps {
  onToggleMenu: () => void
  items: IProductForBtn[]
  onSelectItem: (item: IProductForBtn) => void
}

const Menu: React.VFC<MenuProps> = ({ onToggleMenu, items, onSelectItem }) => {
  return (
    <Card
      width='20em'
      padding={`${theme.space.S}px ${theme.space.XL}px ${theme.space.L}px ${theme.space.S}px`}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          height: '100%'
        }}>
        <IconButton
          margin='0px 10px 0px 0px'
          withBackground
          inline
          bgSecondary
          onClick={onToggleMenu}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>

        <FlexScrollContainer data-ref='FlexScrollContainer'>
          <FlexScrollContainerHead data-ref='FlexScrollContainerHead'>
            <ProductCardHead isMobile={false} title='Übersicht' noToggle='y' />
          </FlexScrollContainerHead>
          <FlexScrollContainerBody pr={theme.space.XXL} data-ref='FlexScrollContainerBody3'>
            <MenuList
              onSelectItem={onSelectItem}
              items={items?.sort((a: IProductForBtn, b: IProductForBtn) =>
                a.title.localeCompare(b.title)
              )}
            />
          </FlexScrollContainerBody>
        </FlexScrollContainer>
      </div>
    </Card>
  )
}

export default Menu
