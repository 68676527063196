import React from 'react'
import styled from 'styled-components'
import { DisplayProps, space, SpaceProps } from 'styled-system'
import { AppTheme as theme } from 'theme/index'

interface CardProps {
  children?: string | React.ReactNode
  className?: string
  title?: string
  isMoblie?: boolean
  width?: number | string
  showContent?: boolean
  inline?: boolean
  control?: React.ReactElement | React.ReactNode
  transparentBg?: boolean
  padding?: string
  overflow?: string
}

export const Card: React.FunctionComponent<CardProps> = (props: CardProps) => {
  const {
    children,
    className,
    control,
    inline,
    isMoblie,
    width,
    showContent,
    transparentBg,
    padding,
    overflow
  } = props

  return isMoblie ? (
    <StyledCard
      className={className}
      width={width}
      showContent={showContent}
      inline={inline}
      padding={padding ? padding : `${(theme.space.S + 'px', theme.space.L + 'px')}`}
      transparentBg={transparentBg}>
      {control}
      {children}
    </StyledCard>
  ) : (
    <StyledCardTablet
      className={className}
      width={width}
      inline={inline}
      overflow={overflow}
      padding={padding ? padding : `${theme.space.L + 'px'}`}>
      {control}
      {children}
    </StyledCardTablet>
  )
}

Card.defaultProps = {
  showContent: false
}

export default Card

const StyledCard = styled.div<CardProps & SpaceProps & DisplayProps>`
  border-radius: 10px;
  background-color: ${(props) => (props.transparentBg ? 'rgba(0, 0, 0, 0)' : 'white')};
  height: 100%;
  width: ${(props: CardProps) => props.width};
  margin: 0 auto;
  box-shadow: ${(props) => !props.transparentBg && '2px 2px 1px rgba(0, 0, 0, 0.1)'};
  display: ${(props: CardProps) => (props.inline ? 'inline-block' : 'block')};
  ${(props) => !props.showContent && { 'margin-top': '15px' }};
  ${space};
`

const StyledCardTablet = styled.div<CardProps & SpaceProps>`
  border-radius: 10px;
  background-color: white;

  width: ${(props: CardProps) => props.width};
  margin: 0 auto;

  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
  display: ${(props: CardProps) => (props.inline ? 'inline-block' : 'block')};
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  height: 100%;
  ${space};
`
