import { Button, IconType, SVGIcon } from 'components/atoms'
import { readableColor } from 'polished'
import React from 'react'
import { AppTheme as theme } from 'theme/index'

interface ContactLinkProps {
  onClick(): void
  context: 'menu' | 'content'
  isMobile?: boolean
}

export const ContactLink: React.FunctionComponent<ContactLinkProps> = ({
  onClick,
  context
}: ContactLinkProps) => {
  return (
    <>
      {context === 'menu' && (
        <div
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}
          onClick={onClick}>
          <div style={{ display: 'block', width: '100%', height: 'auto' }}>
            <div style={{ display: 'table', float: 'left' }}>Kontakt</div>
            <div
              style={{
                display: 'table',
                float: 'right',
                marginTop: '-13px',
                marginBottom: '-11px'
              }}>
              <SVGIcon
                iconName={IconType.IconContact}
                viewBox='0 0 42 42'
                color={theme.colors.primary}
                width='42px'
                height='42px'
                margin='0px 0px 0px 0px '
              />
            </div>
          </div>
        </div>
      )}

      {context === 'content' && (
        <div style={{ paddingBottom: `${theme.space.XXL}px` }}>
          <Button
            bgColor='secondary'
            onClick={onClick}
            iconLeft={IconType.IconContact}
            fullWidth
            textColor={readableColor(theme.colors.secondary, '#000', '#fff')}>
            Kontaktieren
          </Button>
        </div>
      )}
    </>
  )
}

export default ContactLink
