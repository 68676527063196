import { Link, Typography, TypographyType } from 'components/atoms'
import React from 'react'
import styled from 'styled-components'

interface FooterProps {
  imprintURL?: string
  dataProtectionURL?: string
}

const Footer: React.VFC<FooterProps> = ({ imprintURL, dataProtectionURL }) => (
  <TextContainer>
    {!!imprintURL && (
      <div style={{ marginRight: '15px' }}>
        <Link href={imprintURL} noDecoration target='_blank'>
          <Typography type={TypographyType.TEXT_SMALL}>Impressum</Typography>
        </Link>
      </div>
    )}

    {!!dataProtectionURL && (
      <div style={{ marginRight: '15px' }}>
        <Link href={dataProtectionURL} noDecoration target='_blank'>
          <Typography type={TypographyType.TEXT_SMALL}>Datenschutz</Typography>
        </Link>
      </div>
    )}
    <Typography type={TypographyType.TEXT_SMALL}>{`Version: ${process.env.VERSION}`}</Typography>
  </TextContainer>
)

export default Footer

const TextContainer = styled.div`
  padding: 2px 6px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
`
