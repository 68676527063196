import React from 'react'
import styled from 'styled-components'

interface LinkProps extends LinkStyleProps {
  children: string | React.ReactElement | React.ReactNode
  href: string
  target?: string
}

export const Link: React.FunctionComponent<LinkProps> = ({
  children,
  href,
  target,
  noDecoration
}) => {
  return (
    <StyledLink href={href} target={target} noDecoration={noDecoration}>
      {children}
    </StyledLink>
  )
}

export default Link

interface LinkStyleProps {
  noDecoration?: boolean
}

const StyledLink = styled.a<LinkStyleProps>`
  cursor: pointer;
  color: inherit;
  :hover {
    color: black;
  }
  :focus,
  :active {
    color: inherit;
  }
  font-family: ${(props) => props.theme.fontFamily};

  text-decoration: ${(props) => (props.noDecoration ? 'none' : undefined)};
`
