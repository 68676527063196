import { readableColor } from 'polished'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  display,
  DisplayProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system'
import { AppTheme as theme } from 'theme/index'

import { IconType, SVGIcon } from '..'

interface ButtonProps extends ButtonStyleProps {
  onClick?(): void
  fullWidth?: boolean
  children: string | ReactElement
  arrowLink?: boolean
  iconLeft?: IconType
  mb?: any
  mt?: any
  bgColor?: any
  textColor?: string
  iconColor?: any
}

// tslint:disable-next-line: cyclomatic-complexity
export const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const {
    onClick,
    children,
    inline,
    mt,
    mb,
    textColor,
    iconLeft,
    bgColor,
    disabled,
    fullWidth,
    arrowLink,
    padding
  } = props

  return (
    <>
      {arrowLink && (
        <StyledButtonContainer
          className='arrowLinkBtn'
          color={!!textColor ? textColor : readableColor(theme.colors.primary, '#000', '#fff')}
          display={inline ? 'inline-block' : 'block'}
          width={fullWidth ? '100%' : 'auto'}
          border
          p={theme.space.L}
          onClick={onClick}
          disabled={disabled}
          bg='white'
          fontSize='16px'
          fontWeight={theme.fontweight.light}>
          <StyledAlignParent justifyContent='left'>
            <StyledLeftTd>
              <SVGIcon
                iconName={IconType.IconMore}
                viewBox='0 0 42 11'
                color={theme.colors.secondary}
                width='42px'
                height='19px'
                margin='0px 0px 0px 0px '
              />
            </StyledLeftTd>
            <TextAlignContainer textAlign='left'>{children}</TextAlignContainer>
          </StyledAlignParent>
        </StyledButtonContainer>
      )}
      {iconLeft && (
        <StyledButtonContainer
          color={!!textColor ? textColor : readableColor(theme.colors.primary, '#000', '#fff')}
          display={inline ? 'inline-block' : 'block'}
          width={fullWidth ? '100%' : 'auto'}
          bg={disabled ? 'lightGray' : bgColor}
          p={theme.space.L}
          border={false}
          mb={mb ? mb : theme.space.NONE}
          mt={mt ? mt : theme.space.NONE}
          onClick={onClick}
          disabled={disabled}
          fontSize='16px'
          fontWeight={theme.fontweight.light}>
          <StyledAlignParent>
            <StyledLeftTd>
              {/* right now only possible: contact icon, because only that icons needs marginTop: '-13px', marginBottom: '-16px' .... toDo: strip white space from contact icon, repair placement everywhere and avoid other than 42 viebox!*/}
              <div
                style={{
                  display: 'table',
                  float: 'right',
                  marginTop: '-13px',
                  marginBottom: '-16px'
                }}>
                <SVGIcon
                  iconName={iconLeft}
                  viewBox='0 0 42 42'
                  color={readableColor(theme.colors.secondary, '#000', '#fff')}
                  width='42px'
                  height='42px'
                  margin='0px 0px 0px 0px '
                />
              </div>
            </StyledLeftTd>
            <TextAlignContainer textAlign='center'>{children}</TextAlignContainer>
          </StyledAlignParent>
        </StyledButtonContainer>
      )}

      {!arrowLink && !iconLeft && (
        <StyledButtonContainer
          color={!!textColor ? textColor : readableColor(theme.colors.primary, '#000', '#fff')}
          display={inline ? 'inline-block' : 'block'}
          width={fullWidth ? '100%' : 'auto'}
          bg={disabled ? 'lightGray' : bgColor}
          padding={!!padding ? padding : `${theme.space.L}px`}
          border={false}
          onClick={onClick}
          disabled={disabled}
          fontSize='16px'
          fontWeight={theme.fontweight.light}>
          {children}
        </StyledButtonContainer>
      )}
    </>
  )
}

export default Button

Button.defaultProps = {
  inline: false,
  bgColor: 'primary',
  disabled: false,
  border: false
}

interface ButtonStyleProps {
  disabled?: boolean
  inline?: boolean
  bgColor?: string
  width?: string
  border?: boolean
  padding?: string
}

const StyledButtonContainer = styled.button<
  DisplayProps & ColorProps & SpaceProps & TypographyProps & ButtonStyleProps
>`
  ${display}
  ${color}
  ${space}
  ${typography}
  height: 100%;
  width: ${(props) => (props.width ? props.width : 'auto')};
  text-align: center;
  clear: both;
  font-family: ${(props) => props.theme.fontFamily};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  transition: all 0.5s ease;
  outline: none;
  border: ${(props) => (props.border ? '1px solid ' + props.theme.colors.gray : 'none')};

  :focus {
    outline: none;
  }
`
interface TextAlignContainer {
  textAlign: string
}

const TextAlignContainer = styled.div<TextAlignContainer>`
  text-align: ${(props) => props.textAlign};
`

const StyledLeftTd = styled.div``

interface StyledAlignParentProps {
  justifyContent?: string
}

const StyledAlignParent = styled.div<StyledAlignParentProps>`
  word-break: break-word;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
`
