import { IShowInGraphicOptionalLayer } from 'components/statics'
import { useStore } from 'context/index'
import React from 'react'

import GraphicCanvas from './lib/GraphicCanvas'

interface HouseGraphicParentProps {
  svgPath: string
  contentsData: any
  selectedProduct: any
  showInGraphic?: {
    [key: string]: 'y' | 'n'
  }
  showInGraphicAllOptionalLayers?: IShowInGraphicOptionalLayer[]
}

export const HouseGraphicParentMobile: React.FC<HouseGraphicParentProps> = ({
  svgPath,
  contentsData,
  showInGraphic,
  showInGraphicAllOptionalLayers
}: HouseGraphicParentProps) => {
  const { contentStore } = useStore()
  function clickOnCanvasFunction() {
    // event: React.MouseEvent<HTMLElement, MouseEvent>,
    // zoom: number,
    // viewportWidth: number,
    // viewportHeight: number
    console.log('TODO: clickOnCanvasFunction')
    // if (configView.placeImagePointId !== '') {
    //   const nativeevent = event.nativeEvent
    //   const percentX = (nativeevent.offsetX / viewportWidth) * 100
    //   const percentY = (nativeevent.offsetY / viewportHeight) * 100

    //   setGraphicsPositionInContent(configView.placeImagePointId, percentX, percentY)
    // }
  }

  return (
    <>
      <GraphicCanvas
        svgPath={svgPath}
        contentsData={contentsData}
        showInGraphic={showInGraphic}
        clickOnCanvasEnabled={false}
        forceUpdateFromParent
        openAllHoverAreas={contentStore.ContentMeta?.showHoverAreaContents}
        clickOnCanvasFunction={clickOnCanvasFunction}
        showInGraphicAllOptionalLayers={showInGraphicAllOptionalLayers}
        colorPrimary='#9FC3C9'
      />
    </>
  )
}

export default HouseGraphicParentMobile
