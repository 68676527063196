import { Button, Image } from 'components/atoms'
import { useStore } from 'context/index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { AppTheme as theme } from 'theme/index'

interface AtomLinkProps extends BtnContainerProps {
  subModule: any
  linkIndexStart: number
  handleShowContent(): void
}

export const AtomLink: React.FC<AtomLinkProps> = observer(
  ({ subModule, linkIndexStart, handleShowContent }: AtomLinkProps) => {
    const { contentStore, appStore } = useStore()
    // console.log('atom link: ', toJS(subModule))

    let link1Extern = false
    let url1Extern: string = ''
    let link1Text = ''

    let image = ''

    if (
      linkIndexStart + 1 < subModule.atoms.length &&
      (subModule.atoms[linkIndexStart + 1].page.type === 'linkExtern' ||
        subModule.atoms[linkIndexStart + 1].page.type === 'linkExtern1')
    ) {
      link1Extern = true
      link1Text = subModule.atoms[linkIndexStart].val
      url1Extern = subModule.atoms[linkIndexStart + 1].val
    } else {
      if (subModule.atoms[linkIndexStart] && subModule.atoms[linkIndexStart].val) {
        link1Text = subModule.atoms[linkIndexStart].val
      }
    }

    if (
      linkIndexStart < subModule.atoms.length &&
      subModule.atoms[linkIndexStart] &&
      subModule.atoms[linkIndexStart].page.type === 'linkImage'
    ) {
      image = subModule.atoms[linkIndexStart].val
    }

    if (
      linkIndexStart < subModule.atoms.length &&
      subModule.atoms[linkIndexStart].page.type === 'file'
    ) {
      link1Extern = true
      link1Text = 'Datei herunterladen'
      url1Extern = appStore.makeFullUrl(subModule.atoms[linkIndexStart].val as string)
    }

    const clickLink1 = () => {
      if (link1Extern) {
        window.open(url1Extern, '_blank')
      } else if (
        linkIndexStart + 1 < subModule.atoms.length &&
        (subModule.atoms[linkIndexStart + 1].page.type === 'linkIntern' ||
          subModule.atoms[linkIndexStart + 1].page.type === 'linkIntern1')
      ) {
        if (contentStore) {
          // console.log('atom link 2: ', toJS(subModule.atoms[linkIndexStart + 1].val._id))
          contentStore.setFollowUpContentId(subModule.atoms[linkIndexStart + 1].val._id)
        }
        handleShowContent()
      }
    }
    const clickLink2 = () => {
      if (
        linkIndexStart + 3 < subModule.atoms.length &&
        subModule.atoms[linkIndexStart + 3].page.type === 'linkExtern2'
      ) {
        window.open(subModule.atoms[linkIndexStart + 3].val, '_blank')
      } else if (subModule.atoms[linkIndexStart + 3].page.type === 'linkIntern2') {
        if (contentStore) {
          contentStore.setFollowUpContentId(subModule.atoms[linkIndexStart + 3].val._id)
        }
        handleShowContent()
      }
    }
    let twoButtonsInARow = false

    if (
      linkIndexStart + 2 < subModule.atoms.length &&
      subModule.atoms[linkIndexStart + 2] &&
      subModule.atoms[linkIndexStart + 2].page.type === 'linkText2'
    ) {
      twoButtonsInARow = true
    }

    const arrowLink =
      linkIndexStart < subModule.atoms.length &&
      subModule.atoms[linkIndexStart].page.subType &&
      subModule.atoms[linkIndexStart].page.subType === 'arrowLink'

    return (
      <BtnContainer className='Button_Group' hasTwoBtns={twoButtonsInARow}>
        <StyledButtonContainer
          data-ref='StyledButtonContainer1'
          fullWidth={!twoButtonsInARow}
          paddingRight={twoButtonsInARow}>
          {image === '' && link1Text !== '' && (
            <Button
              onClick={clickLink1}
              fullWidth
              arrowLink={arrowLink}
              textColor={!!arrowLink && theme.colors.black}
              disabled={!subModule.atoms[linkIndexStart + 1]}>
              {link1Text}
            </Button>
          )}
          {image !== '' && (
            <Button
              onClick={clickLink1}
              disabled={!subModule.atoms[linkIndexStart + 1]}
              fullWidth
              arrowLink={arrowLink}
              bgColor='white'
              textColor={!!arrowLink && theme.colors.black}
              padding='0px'>
              <Image url={appStore.makeFullUrl(image)} />
            </Button>
          )}
        </StyledButtonContainer>
        {twoButtonsInARow === true && (
          <StyledButtonContainer fullWidth={false}>
            <Button
              onClick={clickLink2}
              fullWidth
              textColor={!!arrowLink && theme.colors.black}
              arrowLink={
                subModule.atoms[linkIndexStart].subType &&
                subModule.atoms[linkIndexStart].subType === 'arrowLink'
              }>
              {subModule.atoms[linkIndexStart + 2].val}
            </Button>
          </StyledButtonContainer>
        )}
      </BtnContainer>
    )
  }
)

export default AtomLink

interface BtnContainerProps {
  hasTwoBtns?: boolean
}

const BtnContainer = styled.div<BtnContainerProps>`
  margin-top: ${(props) => (props.hasTwoBtns ? '24px' : '0px')};
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: no;
`

interface StyledButtonContainerProps {
  fullWidth?: boolean
  paddingRight?: boolean
  paddingBottom?: boolean
}

const StyledButtonContainer = styled.div<StyledButtonContainerProps>`
  -webkit-box-sizing: border-box;
  width: ${(props) => (props.fullWidth === true ? '100%' : '50%')};
  float: left;
  padding-bottom: ${(props) => (props.paddingBottom === true ? '20px' : '0px')};
  padding-right: ${(props) =>
    props.paddingRight === true ? '20px' : '0px'}; /* toDo use space props instead of 20px */
`
