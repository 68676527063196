import React from 'react'
import styled from 'styled-components'
import { color, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { AppTheme as theme } from 'theme/index'

interface ListItemProps {
  children: string | React.ReactElement
  isFirst?: boolean
  isLast?: boolean
  itemIndex?: string | number
  color?: string
  contentId?: string
  is?: string
  // callbackToMainController?: any
  contentIndex?: number //the content index of this menu item
  isMobile?: boolean
  onClick?(): void
}

export const ListItem: React.FunctionComponent<ListItemProps> = ({
  isFirst,
  isLast,
  children,
  itemIndex,
  color,
  contentId,
  is,
  isMobile,
  onClick,
  contentIndex //the content index of this menu item
}) => {
  const handleClick = () => {
    if (isMobile) {
      if (is === 'content') {
        console.log('mobile?')
        // callbackToMainController.mobileChooseProductIndex(contentIndex)
      }
      if (!!onClick) {
        onClick()
      }
    } else {
      console.log(contentId, contentIndex)
      if (is === 'content') {
        console.log('desktop?')
        // callbackToMainController.desktopChooseProduct(contentId)
      }
      if (!!onClick) {
        onClick()
      }
    }
  }

  return (
    <StyledListItem
      pt={theme.space.L}
      pb={theme.space.LminusOne}
      isFirst={isFirst}
      isLast={isLast}
      itemIndex={itemIndex}
      fontWeight={theme.fontweight.medium}
      color={color ? color : 'darkgray'}
      onClick={handleClick}>
      {children}
    </StyledListItem>
  )
}

export default ListItem

const StyledListItem = styled.div<ListItemProps & SpaceProps & TypographyProps>`
  width: 100%;
  list-style: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 16px;
  line-height: 20px;
  /*${(props) => props.isFirst && { 'border-top': 'solid 1px #A0C4C9' }}*/
  :hover {
    color: #51c70f;
  }

  ${color}
  ${space}
  ${typography}
`
