import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { AppTheme as theme } from 'theme/index'

interface SnackbarProps extends SnackbarStyleProps {
  isMobile: boolean
  children: string
}

export const Snackbar: React.FunctionComponent<SnackbarProps> = ({
  isMobile,
  children,
  visible,
  bottom
}) => {
  return isMobile ? (
    <MobileSnackbar
      padding={theme.space.M}
      fontWeight={theme.fontweight.regular}
      fontSize='16px'
      visible={visible}
      bottom={bottom}>
      {children}
    </MobileSnackbar>
  ) : (
    <StyledSnackbar
      padding={theme.space.M}
      fontWeight={theme.fontweight.regular}
      fontSize='16px'
      visible={visible}>
      {children}
    </StyledSnackbar>
  )
}

export default Snackbar

interface SnackbarStyleProps {
  visible?: boolean
  bottom?: string
}

const MobileSnackbar = styled.div<SpaceProps & TypographyProps & SnackbarStyleProps>`
  ${typography}
  ${space}

  display: block;
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
  /* bottom: ${(props) => (props.bottom ? props.bottom : '0px')}; */
  width: 80%;
  text-align: center;
  height: auto;
  color: white;
  line-height: 1.5em;
  font-family: ${(props) => props.theme.fontFamily};
  background: rgba(0, 0, 0, 0.7);

  border-radius: 15px;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.1);
  z-index: 6000;
  ${(props) => props.visible && AnimationEffect}
  pointer-events:none;
`

const StyledSnackbar = styled.div<SpaceProps & TypographyProps & SnackbarStyleProps>`
  ${typography}
  ${space}
  /* display: ${(props) => props.visible === false && 'none'}; */
  opacity: 0;
  position: absolute;
  left: 1em;
  width: 255px;
  text-align: left;
  height: auto;
  color: white;
  line-height: 1.5em;
  font-family: ${(props) => props.theme.fontFamily};
  background: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 4px;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.1);
  z-index: -1;
  ${(props) => props.visible && AnimationEffect}
`

const fading = keyframes`
  0% { opacity: 0; display: inline-block; z-index: 1000  }
  20% { opacity: 1; z-index: 1000 }
  70% { opacity: 1;z-index: 1000 }
  95% { opacity: 0; z-index: 1000 }
  100% { display: none; z-index: -1 }
`

const AnimationEffect = css`
  animation: ${fading} 3s ease;
`
