import React from 'react'
import styled from 'styled-components'
import {
  color,
  display,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps
} from 'styled-system'
import { AppTheme as theme } from 'theme/index'

import { TypoTypes } from './static'

interface TypographyProps {
  children: string | React.ReactElement | React.ReactNode
  className?: string
  color?: string
  inline?: boolean

  type?: TypoTypes
  alignment?: 'left' | 'center' | 'right'

  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  // height?: string
  // overflow?: string
}

export const Typography: React.FC<TypographyProps> = (props: TypographyProps) => {
  const renderTypograph = (props: TypographyProps) => {
    const { type, children, color } = props

    switch (type) {
      case 'productHead':
        return React.createElement(
          Text,
          {
            color: 'black',
            fontSize: '18px',
            lineHeight: '27px',
            fontWeight: 'bolder',

            ...props
          },
          children
        )
      case 'head':
        return React.createElement(
          Text,
          {
            color: 'darkgray',
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: 'bolder',
            ...props
          },
          children
        )
      case 'subHead':
        return React.createElement(
          Text,
          {
            color: 'darkgray',
            fontSize: '17px',
            px: theme.space.L,
            lineHeight: '20px',
            fontWeight: 'bolder',
            ...props
          },
          children
        )
      case 'textS':
        return React.createElement(
          Text,
          {
            color: 'darkgray',
            fontSize: '13px',
            lineHeight: '16px',
            fontWeight: 'normal',
            ...props
          },
          children
        )
      case 'category':
        return React.createElement(
          Text,
          {
            color: 'darkgray',
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 'bolder',
            ...props
          },
          children
        )
      case 'footer':
        return React.createElement(
          Text,
          {
            color: 'darkgray',
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 'normal',
            ...props
          },
          children
        )
      case 'text':
        return React.createElement(
          Text,
          {
            color: color ? color : 'darkgray',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 'normal',
            ...props
          },
          children
        )
      default:
        return React.createElement(
          Text,
          {
            ...props
          },
          children
        )
    }
  }

  return renderTypograph(props)
}

export default Typography

Typography.defaultProps = {
  inline: false
}

const Text = styled.div<
  FontSizeProps & FontWeightProps & LineHeightProps & FontFamilyProps & TypographyProps
>`
  ${color}
  ${fontSize}
  ${fontWeight}
  ${fontFamily}
  ${lineHeight}
  ${display}
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  text-align: ${(props) => props.alignment};
  transition: all 0.3s ease;
  white-space: pre-wrap;
  font-family: ${(props) => props.theme.fontFamily};
`
