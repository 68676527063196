import { ContactLink } from 'components/atoms'
import { ModuleLoader } from 'components/molecules'
import { useDevice, useStore } from 'context/index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { AppTheme as theme } from 'theme/index'

import { IModule, ISubModule } from './statics'
import { BlockContainer } from './styles'
import { SubModule } from './subModule'

interface ModuleProps {
  modules: IModule[]
  kind?: string
  isParentOn?: boolean
  handleImageLoad?(): void
  handleShowContent?(): void
}

//toDo: rename this module to EchoContent and wrap the module echo in seperate module
export const Module: React.FunctionComponent<ModuleProps> = observer(
  ({ modules, handleShowContent, kind, isParentOn, handleImageLoad }: ModuleProps) => {
    const { contentStore, uiStore } = useStore()
    const { isMobile } = useDevice()

    let isFollowUpContent = false
    if (kind && kind === 'followUpContent') {
      isFollowUpContent = true
    }

    React.useEffect(() => {
      if (!isFollowUpContent) {
        uiStore.updateAllImagesInModulesLoaded(false)
        Promise.all(
          contentStore.allImagesInModules.map((image: { url: string }) =>
            contentStore.loadImagesInModules(image)
          )
        )
          .then(() => {
            uiStore.updateAllImagesInModulesLoaded(true)
            console.log('all images loaded: ', uiStore.allImagesInModulesLoaded)
          })
          .catch((err) => console.log('Failed to load images', err))
      }
    }, [modules])

    const handleOpenContact = () => {
      if (isMobile) {
        uiStore.handleFlipCard(false, 'contact')
        uiStore.updateContactCardTrigger('product')
      } else {
        uiStore.updateShowContactCard(true)
      }
    }

    return (
      <React.Fragment>
        {!!modules &&
          modules.length > 0 &&
          modules.map((module: IModule, index: number) => {
            return (
              <React.Fragment key={index}>
                {module.subModules.length > 0 && (
                  // Module Container some bottom space comes from submodules und atoms, so only XXL

                  <BlockContainer
                    key={index}
                    data-ref={`BlockContainerModule-${module.meta.global.id}`}
                    mb={module.meta.global.id === 'TIPPS' ? theme.space.XL : theme.space.NONE}>
                    {module.subModules.map((subModule: ISubModule, index: number) => (
                      <React.Fragment key={index}>
                        {uiStore.allImagesInModulesLoaded ? (
                          <SubModule
                            key={index}
                            subModule={subModule}
                            moduleId={module.meta.global.id}
                            kind={kind}
                            isParentOn={isParentOn}
                            handleImageLoad={handleImageLoad}
                            handleShowContent={handleShowContent}
                          />
                        ) : (
                          <ModuleLoader />
                        )}
                      </React.Fragment>
                    ))}
                  </BlockContainer>
                )}
              </React.Fragment>
            )
          })}
        {!isFollowUpContent && uiStore.allImagesInModulesLoaded && (
          <ContactLink isMobile={isMobile} context='content' onClick={handleOpenContact} />
        )}
      </React.Fragment>
    )
  }
)

export default Module
