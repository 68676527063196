import React from 'react'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  display,
  DisplayProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps
} from 'styled-system'

import {
  Check,
  IconAbwasser,
  IconAlarmanlage,
  IconBack,
  IconBath,
  IconBikes,
  IconBikesharing,
  IconBurger,
  IconCarsharing,
  IconClose,
  IconColdContracting,
  IconContact,
  IconDefault10,
  IconDefault11,
  IconDefault12,
  IconDefault7,
  IconDefault8,
  IconDefault9,
  IconDistanceHeating,
  IconDrinkingWater,
  IconDrinkingWaterAnalysis,
  IconEBikes,
  IconElectric,
  IconEmobility,
  IconEnergieausweis,
  IconEnergiesparverordnung,
  IconEnergyConsulting,
  IconEnergySavingTip,
  IconErklaervideos,
  IconFoerderung,
  IconGas,
  IconGlasfaser,
  IconHeat,
  IconHouseConnection,
  IconLadesaeule,
  IconLight,
  IconMinus,
  IconMore,
  IconMoveHouse,
  IconOekostrom,
  IconOnlineKundensupport,
  IconPlus,
  IconPublicTransport,
  IconPV,
  IconRasenmaeher,
  IconRegionalstrom,
  IconRememberOff,
  IconRememberOn,
  IconRentersElectricity,
  IconSmartHome,
  IconSolar,
  IconStart,
  IconTelecommunication,
  IconThermography,
  IconThermography02,
  IconThermography03,
  IconTrash,
  IconTreppenlift,
  IconType,
  IconUp,
  IconUsedWater,
  IconWaerme,
  IconWallbox,
  IconWanderwege,
  Test,
  Times
} from './iconLib'

interface SVGIconProps extends IconContainerProps {
  xmlns?: string
  viewBox?: string
  iconName: IconType | string
}

// tslint:disable-next-line: cyclomatic-complexity
const renderPath = (name: string, props: any) => {
  switch (name) {
    case IconType.Times:
      return <Times {...props} />
    case IconType.Check:
      return <Check {...props} />
    case IconType.Test:
      return <Test {...props} />
    case IconType.IconBath:
      return <IconBath {...props} />
    case IconType.IconCarsharing:
      return <IconCarsharing {...props} />
    case IconType.IconColdContracting:
      return <IconColdContracting {...props} />
    case IconType.IconDistanceHeating:
      return <IconDistanceHeating {...props} />
    case IconType.IconDrinkingWater:
      return <IconDrinkingWater {...props} />
    case IconType.IconDrinkingWaterAnalysis:
      return <IconDrinkingWaterAnalysis {...props} />
    case IconType.IconElectric:
      return <IconElectric {...props} />
    case IconType.IconEmobility:
      return <IconEmobility {...props} />
    case IconType.IconEnergyConsulting:
      return <IconEnergyConsulting {...props} />
    case IconType.IconEnergySavingTip:
      return <IconEnergySavingTip {...props} />
    case IconType.IconGas:
      return <IconGas {...props} />
    case IconType.IconHeat:
      return <IconHeat {...props} />
    case IconType.IconHouseConnection:
      return <IconHouseConnection {...props} />
    case IconType.IconLight:
      return <IconLight {...props} />
    case IconType.IconMoveHouse:
      return <IconMoveHouse {...props} />
    case IconType.IconPublicTransport:
      return <IconPublicTransport {...props} />
    case IconType.IconRentersElectricity:
      return <IconRentersElectricity {...props} />
    case IconType.IconSmartHome:
      return <IconSmartHome {...props} />
    case IconType.IconSolar:
      return <IconSolar {...props} />
    case IconType.IconStart:
      return <IconStart {...props} />
    case IconType.IconTelecommunication:
      return <IconTelecommunication {...props} />
    case IconType.IconThermography:
      return <IconThermography {...props} />
    case IconType.IconTrash:
      return <IconTrash {...props} />
    case IconType.IconUsedWater:
      return <IconUsedWater {...props} />

    case IconType.IconBikes:
      return <IconBikes {...props} />
    case IconType.IconBikesharing:
      return <IconBikesharing {...props} />
    case IconType.IconEBikes:
      return <IconEBikes {...props} />
    case IconType.IconEnergieausweis:
      return <IconEnergieausweis {...props} />
    case IconType.IconEnergiesparverordnung:
      return <IconEnergiesparverordnung {...props} />
    case IconType.IconFoerderung:
      return <IconFoerderung {...props} />
    case IconType.IconOekostrom:
      return <IconOekostrom {...props} />
    case IconType.IconOnlineKundensupport:
      return <IconOnlineKundensupport {...props} />
    case IconType.IconErklaervideos:
      return <IconErklaervideos {...props} />
    case IconType.IconRegionalstrom:
      return <IconRegionalstrom {...props} />

    case IconType.IconAbwasser:
      return <IconAbwasser {...props} />
    case IconType.IconAlarmanlage:
      return <IconAlarmanlage {...props} />
    case IconType.IconGlasfaser:
      return <IconGlasfaser {...props} />
    case IconType.IconLadesaeule:
      return <IconLadesaeule {...props} />
    case IconType.IconPV:
      return <IconPV {...props} />
    case IconType.IconRasenmaeher:
      return <IconRasenmaeher {...props} />
    case IconType.IconTreppenlift:
      return <IconTreppenlift {...props} />
    case IconType.IconWaerme:
      return <IconWaerme {...props} />
    case IconType.IconWallbox:
      return <IconWallbox {...props} />
    case IconType.IconWanderwege:
      return <IconWanderwege {...props} />
    case IconType.IconThermography02:
      return <IconThermography02 {...props} />
    case IconType.IconThermography03:
      return <IconThermography03 {...props} />

    case IconType.IconDefault7:
      return <IconDefault7 {...props} />
    case IconType.IconDefault8:
      return <IconDefault8 {...props} />
    case IconType.IconDefault9:
      return <IconDefault9 {...props} />
    case IconType.IconDefault10:
      return <IconDefault10 {...props} />
    case IconType.IconDefault11:
      return <IconDefault11 {...props} />
    case IconType.IconDefault12:
      return <IconDefault12 {...props} />

    case IconType.IconContact:
      return <IconContact {...props} />
    case IconType.IconMore:
      return <IconMore {...props} />
    case IconType.IconClose:
      return <IconClose {...props} />
    case IconType.IconBack:
      return <IconBack {...props} />
    case IconType.IconBurger:
      return <IconBurger {...props} />
    case IconType.IconPlus:
      return <IconPlus {...props} />
    case IconType.IconMinus:
      return <IconMinus {...props} />
    case IconType.IconUp:
      return <IconUp {...props} />
    case IconType.IconRememberOn:
      return <IconRememberOn {...props} />
    case IconType.IconRememberOff:
      return <IconRememberOff {...props} />
    default:
      return null
  }
}

export const SVGIcon: React.FunctionComponent<SVGIconProps> = ({
  xmlns,
  viewBox,
  color,
  width,
  height,
  margin,
  padding,
  position,
  display,
  iconName
}) => {
  // tslint:disable-next-line: cyclomatic-complexity
  const shouldScale = (name: IconType | string) => {
    return (
      name === IconType.IconAbwasser ||
      name === IconType.IconLadesaeule ||
      name === IconType.IconAlarmanlage ||
      name === IconType.IconGlasfaser ||
      name === IconType.IconPV ||
      name === IconType.IconRasenmaeher ||
      name === IconType.IconTreppenlift ||
      name === IconType.IconWaerme ||
      name === IconType.IconWallbox ||
      name === IconType.IconWanderwege ||
      name === IconType.IconThermography02 ||
      name === IconType.IconThermography03 ||
      name.includes('Default')
    )
  }

  return (
    <IconContainer
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      position={position}
      display={display}>
      <svg
        xmlns={xmlns}
        viewBox={viewBox}
        width={width}
        height={height}
        transform={shouldScale(iconName) ? 'scale(0.75)' : undefined}
        style={{ transform: shouldScale(iconName) ? 'scale(0.75)' : undefined }}>
        {renderPath(iconName, { fill: `${color}` })}
      </svg>
    </IconContainer>
  )
}

SVGIcon.defaultProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 352 512'
}

export default SVGIcon

interface IconContainerProps {
  position?: 'relative' | 'absolute' | 'fixed'
  color?: string
  hover?: boolean
  onClick?(e: React.MouseEvent<HTMLSpanElement>): void
  rotate?: number
  width: number | string
  height: number | string
  margin?: string
  padding?: string
  display?: 'inline' | 'inline-block' | 'block'
}

const IconContainer = styled.span<
  SpaceProps & ColorProps & PositionProps & LayoutProps & DisplayProps & IconContainerProps
>`
  ${position}
  ${color}
  ${space}
  ${layout}
  ${display}
`
