import { IconButton, IconType, SVGIcon } from 'components/atoms'
import { Card, ProductCardHead } from 'components/molecules'
import { IEHContent } from 'components/statics'
import { useStore } from 'context/index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { color, ColorProps, position, PositionProps, space, SpaceProps } from 'styled-system'
import {
  FlexScrollContainer,
  FlexScrollContainerBody,
  FlexScrollContainerBodyInner,
  AppTheme as theme
} from 'theme/index'

import { Module } from '../EchoContent'

interface ProductCardProps {
  onClose?(): void
  onShowFollowingContent?(): void
  onCloseFollowingContent?(): void
  showFollowingContent?: boolean
  showContact?: boolean
  product?: IEHContent
}
export const ProductCard: React.FunctionComponent<ProductCardProps> = observer(
  ({
    onClose,
    onShowFollowingContent,
    onCloseFollowingContent,
    showFollowingContent,
    product
  }: ProductCardProps) => {
    const { contentStore } = useStore()
    const flexBodyRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
      if (flexBodyRef.current) {
        console.log('scrollTop: ', flexBodyRef.current.scrollTop)
        flexBodyRef.current.scrollTo(0, 0)
        console.log('scrollTop: ', flexBodyRef.current.scrollTop)
      }
    }, [flexBodyRef])

    const handleClose = () => {
      if (!!onClose) {
        onClose()
      }
      console.log('close product card')
    }

    const handleShowContent = () => {
      if (!!onShowFollowingContent) {
        onShowFollowingContent()
      }

      console.log('show more content')
    }

    const closeFollowingContent = () => {
      if (!!onCloseFollowingContent) {
        onCloseFollowingContent()
      }
    }

    return (
      <>
        <StyledContainer
          bg='secondary'
          className='product-card'
          showFollowingContent={showFollowingContent}>
          {!!showFollowingContent && (
            <TitleContainer pt={theme.space.XS} pb={theme.space.S}>
              <IconButton color='white' inline small onClick={closeFollowingContent}>
                <SVGIcon
                  iconName={IconType.IconBack}
                  viewBox='0 0 42 23'
                  color={theme.colors.white}
                  width='42px'
                  height='23px'
                  margin='10px 0px 0px 10px'
                />
              </IconButton>
            </TitleContainer>
          )}
          <ProductContainer showFollowingContent={showFollowingContent}>
            <Card
              overflow='auto'
              padding={`${theme.space.M}px ${theme.space.XXL}px ${theme.space.L}px`}>
              <FlexScrollContainer data-ref='FlexScrollContainerHead'>
                <React.Fragment>
                  {!!product && <ProductCardHead product={product} />}
                  {!showFollowingContent && (
                    <TimesButtonContainer
                      position='absolute'
                      right={theme.space.S}
                      top={`calc(40px + ${theme.space.S}px)`}>
                      <IconButton onClick={handleClose} color='secondary'>
                        <SVGIcon
                          iconName={IconType.IconClose}
                          viewBox='0 0 42 42'
                          color={theme.colors.secondary}
                          width='42px'
                          height='42px'
                          margin='10px 0px 0px 10px'
                        />
                      </IconButton>
                    </TimesButtonContainer>
                  )}
                </React.Fragment>
                <FlexScrollContainerBody data-ref='FlexScrollContainerBody4' ref={flexBodyRef}>
                  <FlexScrollContainerBodyInner>
                    {!!contentStore.actualProductModules && (
                      <>
                        <Module
                          modules={contentStore.actualProductModules}
                          handleShowContent={handleShowContent}
                        />
                      </>
                    )}
                  </FlexScrollContainerBodyInner>
                </FlexScrollContainerBody>
              </FlexScrollContainer>
            </Card>
          </ProductContainer>
        </StyledContainer>
      </>
    )
  }
)

export default ProductCard

const StyledContainer = styled.div<ColorProps & Partial<ProductCardProps>>`
  display: inline-block;
  height: ${(props) => props.theme.WindowInnerHeight}px;
  width: 30vw;

  /* .appIsInModal &  {
    width: ${(props) => (props.showFollowingContent ? '30vw' : 'calc(30vw - 40px)')};
    height: calc(100vh - 60px);
  } */

  transform: translateX(${(props) => (props.showFollowingContent ? '-70vw' : '0')});

  /* .appIsInModal {
    transform: translateX(${(props) => (props.showFollowingContent ? '70vw' : '0')});
  } */

  transition: transform 0.6s ease;

  ${color}
`
const ProductContainer = styled.div<Partial<ProductCardProps>>`
  margin-top: ${(props) => (props.showFollowingContent ? '0px' : '40px')};
  height: calc(100% - 40px);
`

const TitleContainer = styled.div<SpaceProps>`
  ${space}
  display: inline-block;
  width: 100%;
  height: 40px;
`

const TimesButtonContainer = styled.div<SpaceProps & PositionProps>`
  ${position}
  ${space}
`
