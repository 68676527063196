import { IEHContent, IProductForBtn, IShowInGraphicOptionalLayer } from 'components/statics'
import { useDevice } from 'context/index'
import { useStore } from 'context/index'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'

import HouseGraphicButton from '../HouseGraphicButton'

interface IInteractiveGraphicProps {
  svgPath: string
  contentsData?: IEHContent[]
  showInGraphicAllOptionalLayers: IShowInGraphicOptionalLayer[]
  showInGraphic?: { [key: string]: 'y' | 'n' }
  clickOnCanvasFunction: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    zoom: number,
    viewportWidth: number,
    viewportHeight: number
  ) => void
  forceUpdateFromParent?: boolean
  clickOnCanvasEnabled: boolean
  openAllHoverAreas: boolean
  colorPrimary?: string
  houseGraphicMinHeight?: number
  btnCanvasViewBoxSize?: number
}

interface OptionsLayersInterface {
  name: string
  label: string
  id: string
  view: boolean
}

/**
 * @param value original float number
 * @param n the number of decimal places to keep
 * @returns number with n decimal places
 */
function roundDecimalPlaces(value: number, n: number): number {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n)
}

export const InteractiveGraphic: React.FunctionComponent<IInteractiveGraphicProps> = observer(
  (props: IInteractiveGraphicProps) => {
    const { isMobile } = useDevice()
    const { uiStore, contentStore } = useStore()

    let zoomFactor: number = 1
    let marginLeftMinus: number = 0
    let marginTopMinus: number = 0
    const heightCorrection = 0
    const viewportWidth = 2124
    const viewportHeight = 1353
    const heightCorrectionPlusHeight = viewportHeight + heightCorrection

    const Canvas = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const Graphic = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const Complete = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const OverGraphic = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const [buttonOffsetPercent, setButtonOffsetPercent] = React.useState<{ x: number; y: number }>({
      x: 0,
      y: 0
    })
    const [optionalLayers, setOptionsLayers] = React.useState<OptionsLayersInterface[]>([])

    useEffect(() => {
      setOptionsLayers([
        {
          name: 'trash',
          label: 'Mülleimer',
          id: 'Muelleimer',
          view: props.showInGraphic?.trash === 'y'
        },
        {
          name: 'moveHouse',
          label: 'Umzugskartons',
          id: 'Umzug',
          view: props.showInGraphic?.moveHouse === 'y'
        },
        {
          name: 'bus',
          label: 'Bushaltestelle',
          id: 'Bushaltestelle',
          view: props.showInGraphic?.bus === 'y'
        },
        {
          name: 'carsharing',
          label: 'Carsharing-Station',
          id: 'Carsharing_Station',
          view: props.showInGraphic?.carsharing === 'y'
        },
        {
          name: 'emobility',
          label: 'E-Mobil',
          id: 'Elektromobilitaet',
          view: props.showInGraphic?.emobility === 'y'
        },
        { name: 'ac', label: 'Klimaanlage', id: 'AC', view: props.showInGraphic?.ac === 'y' }
      ])

      setButtonOffsetPercent({
        x: 0,
        y: (42 / viewportHeight) * 100
      })
      onResize()
    }, [])

    const clickOnCanvas = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!!props.clickOnCanvasFunction) {
        onResize()
        props.clickOnCanvasFunction(event, zoomFactor, viewportWidth, viewportHeight)
      }
    }

    const onResize = () => {
      if (Canvas.current) {
        if (isMobile) {
          const canvasWidth = Canvas.current.offsetWidth
          const canvasHeight = Canvas.current.offsetHeight

          const zoomFactorCalcHorizontal = canvasWidth / 850
          const zoomFactorCalcVertical = canvasHeight / 750

          if (zoomFactorCalcVertical < zoomFactorCalcHorizontal) {
            zoomFactor = zoomFactorCalcVertical
          } else {
            zoomFactor = zoomFactorCalcHorizontal
          }

          marginLeftMinus = (viewportWidth - canvasWidth) / 2 + 10
          marginTopMinus = (viewportHeight - canvasHeight) / 2 - 30
        } else {
          const canvasWidth = Canvas.current.offsetWidth
          const canvasHeight = Canvas.current.offsetHeight

          const zoomFactorCalcHorizontal = canvasWidth / 900
          const zoomFactorCalcVertical = canvasHeight / 800

          if (zoomFactorCalcVertical < zoomFactorCalcHorizontal) {
            zoomFactor = zoomFactorCalcVertical
          } else {
            zoomFactor = zoomFactorCalcHorizontal
          }

          marginLeftMinus = (viewportWidth - canvasWidth) / 2
          marginTopMinus = (viewportHeight - canvasHeight + 65) / 2
        }

        if (Complete.current) {
          Complete.current.style.transform = 'scale(' + zoomFactor + ')'
          Complete.current.style.marginLeft = '-' + marginLeftMinus + 'px'
          Complete.current.style.marginTop = '-' + marginTopMinus + 'px'
        }
      }
    }
    return (
      <StyledScrollArea
        ref={Canvas}
        id='housegraphicCanvasOuterDiv'
        overflow={isMobile ? 'visible' : 'hidden'}>
        <ReactResizeDetector handleWidth handleHeight onResize={onResize} />

        <StyledCompleteCanvas
          ref={Complete}
          id='housegraphicCanvasWithNegativeMargins'
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}>
          <StyledInteractiveGraphicCanvas data-ref='StyledInteractiveGraphicCanvas' ref={Graphic}>
            {!!uiStore.specialElemsInHouse && (
              <StyledContainer style={{ backgroundColor: '#000' }} data-ref='StyledContainer'>
                {!!isMobile ? (
                  <SvgLoader width='100%' path={props.svgPath} viewBox='0 0 2124 1353'>
                    {!!uiStore.specialElemsInHouse &&
                      uiStore.specialElemsInHouse.map((item) => (
                        <div key={`wewef${item.id}`}>
                          <SvgProxy
                            // onMouseOver={() => toggleVisibleById(true, item.id)}
                            // onMouseOut={() => toggleVisibleById(false, item.id)}
                            selector={`#${item.id}hover`}
                            display='block'
                          />
                          <SvgProxy
                            selector={`#${item.id}`}
                            display={
                              props.openAllHoverAreas && !item.justFunObject ? 'block' : 'none'
                            }
                          />
                        </div>
                      ))}
                    {!!optionalLayers &&
                      optionalLayers.length > 0 &&
                      optionalLayers.map((item, index) => (
                        <SvgProxy
                          key={`prop_${index}`}
                          selector={`#${item.id}`}
                          display={item.view ? 'block' : 'none'}
                        />
                      ))}
                    {!!props.colorPrimary && (
                      <SvgProxy selector='.primaryColorFill' fill={props.colorPrimary} />
                    )}
                  </SvgLoader>
                ) : (
                  <SvgLoader width='100%' path={!isMobile && props.svgPath} viewBox='0 0 2124 1353'>
                    {!!uiStore.specialElemsInHouse &&
                      uiStore.specialElemsInHouse.map((item) => (
                        <div key={`wewef${item.id}`}>
                          <SvgProxy
                            onMouseOver={() => uiStore.updateSpecialElemsInHouse(item.id, true)}
                            onMouseOut={() => uiStore.updateSpecialElemsInHouse(item.id, false)}
                            selector={`#${item.id}hover`}
                            display='block'
                          />
                          <SvgProxy
                            selector={`#${item.id}`}
                            display={
                              props.openAllHoverAreas
                                ? item.view === true
                                  ? 'block'
                                  : 'none'
                                : 'none'
                            }
                          />
                        </div>
                      ))}
                    {!!optionalLayers &&
                      optionalLayers.length > 0 &&
                      optionalLayers.map((item, index) => (
                        <SvgProxy
                          key={`prop_${index}`}
                          selector={`#${item.id}`}
                          display={item.view ? 'block' : 'none'}
                        />
                      ))}
                    {!!props.colorPrimary && (
                      <SvgProxy selector='.primaryColorFill' fill={props.colorPrimary} />
                    )}
                  </SvgLoader>
                )}
              </StyledContainer>
            )}
          </StyledInteractiveGraphicCanvas>

          <StyledOverInteractiveGraphicCanvas
            clickEnabled={props.clickOnCanvasEnabled}
            data-ref='StyledOverInteractiveGraphicCanvas'
            heightCorrection={heightCorrection}
            heightCorrectionPlusHeight={heightCorrectionPlusHeight}
            ref={OverGraphic}
            onClick={(event) => {
              clickOnCanvas(event)
            }}>
            {contentStore.ProductsForBtnAndMenu?.length >= 1 &&
              contentStore.ProductsForBtnAndMenu?.map((item: IProductForBtn) => {
                return (
                  <ButtonCanvas
                    id={`ButtonCanvas_${item.id}`}
                    zoom={1}
                    key={item.id}
                    marginLeft={`${roundDecimalPlaces(parseFloat(item.x), 4)}%`}
                    marginTop={`${roundDecimalPlaces(
                      parseFloat(item.y) - buttonOffsetPercent.y,
                      4
                    )}%`}
                    className='do-not-zoom'
                    onMouseOver={() =>
                      uiStore.updateSpecialElemsInHouse(item.specialSVGElementId, true)
                    }
                    onMouseOut={() =>
                      uiStore.updateSpecialElemsInHouse(item.specialSVGElementId, false)
                    }>
                    <>
                      {!!item.customerPage && item.customerPage !== '' ? (
                        <a
                          href={item.customerPage}
                          target='_blank'
                          style={{ textDecoration: 'none' }}
                          rel='noreferrer'>
                          <ButtonInnerCanvas>
                            <HouseGraphicButton
                              product={item}
                              isSelected={
                                contentStore.selectedProductForBtn !== undefined &&
                                item.id === contentStore.selectedProductForBtn.id
                              }
                              title={item.title}
                              // onClick={() => contentStore.updateSelectedProductForBtn(item)}
                            />
                          </ButtonInnerCanvas>
                        </a>
                      ) : (
                        <ButtonInnerCanvas>
                          <HouseGraphicButton
                            product={item}
                            isSelected={
                              contentStore.selectedProductForBtn !== undefined &&
                              item.id === contentStore.selectedProductForBtn.id
                            }
                            title={item.title}
                            onClick={() => contentStore.updateSelectedProductForBtn(item)}
                          />
                        </ButtonInnerCanvas>
                      )}
                    </>
                  </ButtonCanvas>
                )
              })}
          </StyledOverInteractiveGraphicCanvas>
        </StyledCompleteCanvas>
      </StyledScrollArea>
    )
  }
)

export default InteractiveGraphic

const StyledScrollArea = styled.div<{ overflow: string }>`
  display: block;
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  overflow: ${(props) => props.overflow};
`

const StyledContainer = styled.div`
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0) !important;
  & > svg {
    border-radius: 10px;
  }
`

interface ButtonCanvasProps {
  zoom: number
  marginTop: any
  marginLeft: any
}

const ButtonCanvas = styled.div<ButtonCanvasProps>`
  /*
  margin-top: calc(${(props) => (props.marginTop ? props.marginTop : '50')}% - 21px);
  margin-left: calc(${(props) => (props.marginLeft ? props.marginLeft : '50')}% - 21px);
  */
  top: ${(props) => (props.marginTop ? props.marginTop : '50%')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '50%')};
  width: auto; /* space for mouse over label: 220px */
  height: 42px;
  display: table;
  position: absolute;
  pointer-events: auto;
  transform: scale(${(props) => props.zoom}) !important;
`

const ButtonInnerCanvas = styled.div`
  pointer-events: auto;
`

interface IStyledCompleteCanvasProps {
  viewportWidth: number
  viewportHeight: number
}

const StyledCompleteCanvas = styled.div<IStyledCompleteCanvasProps>`
  display: block;
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;

  width: 2124px;
  height: 1353px;
  box-sizing: border-box;
  overflow-y: hidden;
`

const StyledInteractiveGraphicCanvas = styled.div`
  display: block;
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;

  width: 2124px;
  height: 1353px;
  box-sizing: border-box;
`

interface StyledOverInteractiveGraphicCanvasProps {
  clickEnabled: boolean
  heightCorrection: number
  heightCorrectionPlusHeight: number
}
const StyledOverInteractiveGraphicCanvas = styled.div<StyledOverInteractiveGraphicCanvasProps>`
  display: block;
  position: absolute;
  pointer-events: ${(props) => (props.clickEnabled ? 'inherit' : 'none')};
  margin-top: -${(props) => props.heightCorrection}px;
  margin-left: 0px;
  width: 2124px;
  height: ${(props) => props.heightCorrectionPlusHeight}px;
  box-sizing: border-box;
`
