import { IEHContent, IProductForBtn } from 'components/statics'
import { ICMSPage, IModule, ISubModule, ISubModuleAtom } from 'components/templates'
import { makeAutoObservable, runInAction, toJS } from 'mobx'

import { API } from '../api'

import RootStore from './RootStore'

class ContentStore {
  private rootStore: RootStore
  public api = new API()
  public Contents: IEHContent[] = []
  public ContentMeta: any = {}
  public mobileInitAnimationIsDone: boolean = false
  public selectedProductForBtn: IProductForBtn | undefined = undefined
  public actualProductId: string = ''
  public actualProductInfo: IEHContent | undefined = undefined
  public actualProductModules: IModule[] | undefined = undefined
  // public loadedCMSPages: ICMSPage[] = []
  public cmsPageId: string = ''
  public followUpContent: any = undefined
  public followUpContentLoaded: boolean = false
  public actualDetailsContentId: string = ''
  public watchListContentIdArr: string[] = []
  public allImagesInModules: { url: string }[] = []
  // public actualProductForMobile: IEHContent | undefined = undefined

  public constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    // this.loadedCMSPages = []
  }

  public emptyWatchList() {
    this.watchListContentIdArr.length = 0
    console.log('watch list cleared')
  }

  public getWatchListAsArrayOfObj() {
    const returnArr: any[] = []
    for (let index = 0; index < this.watchListContentIdArr.length; index++) {
      returnArr.push({ id: this.watchListContentIdArr[index], checked: true })
    }
    return returnArr
  }

  public checkIfIdIsOnWatchList(id: string): boolean {
    const index = this.watchListContentIdArr.findIndex((idInArr) => idInArr === id)
    if (index > -1) {
      return true
    } else {
      return false
    }
  }

  public toggleIdOnWatchlist(id: string) {
    const index = this.watchListContentIdArr.findIndex((idInArr) => idInArr === id)

    if (index > -1) {
      this.watchListContentIdArr.splice(index, 1)
    } else {
      this.watchListContentIdArr.push(id)
    }
    console.log(this.watchListContentIdArr)
  }

  // public getAllContents() {
  //   return new Promise((resolve) => {
  //     this.api.getAllContents().then((data: any) => {
  //       this.Contents = data.contents
  //       this.ContentMeta = data.meta
  //       // if (this.Contents.length > 0) {
  //       //   this.updateActualProductForMobile(this.Contents[0])
  //       // }
  //       resolve(data)
  //     })
  //   })
  // }

  async getAllContents() {
    const data: any = await this.api.getAllContents()
    this.Contents = data.contents
    this.ContentMeta = data.meta
  }

  public getModuleContentByCmsPageId(id: string) {
    if (id !== '') {
      // const loadedPages: ICMSPage[] = this.loadedCMSPages.filter(
      //   (page: ICMSPage) => page._id === id
      // )
      // const isCMSPageLoaded = this.loadedCMSPages.length > 0 ? loadedPages.length > 0 : false

      // if (isCMSPageLoaded) {
      //   this.updateActualProductModules(loadedPages[0].modules)
      //   return loadedPages[0].modules
      // } else {
      console.log('id: ', id)
      return new Promise((resolve) => {
        this.api.getOneContent(id).then((data: ICMSPage) => {
          console.log('getOneContent from server', id, data)
          if (!!data && !!data[0].modules) {
            this.updateActualProductModules(data[0].modules)
            // this.loadedCMSPages.push(data)
            const imgArr: { url: string }[] = []

            data[0].modules.map((module: IModule) => {
              module.subModules.map((subModule: ISubModule) => {
                subModule.atoms.map((atom: ISubModuleAtom) => {
                  if (atom.page.type === 'image' && !!atom.val && atom.val !== '') {
                    imgArr.push({ url: process.env.APP_UPLOADS_BASE_URL + atom.val })
                  }
                })
              })
            })

            this.updateAllImagesInModules(imgArr)
            resolve(data)
          } else {
            this.updateActualProductModules(undefined)
            resolve(undefined)
          }
        })
      })
      // }
    } else {
      this.updateActualProductModules(undefined)
      return undefined
    }
  }

  public updateAllImagesInModules = (images: { url: string }[]) => {
    this.allImagesInModules = images
  }

  public getActualDetailsContentId() {
    return this.actualDetailsContentId
  }

  get ProductsForBtnAndMenu(): IProductForBtn[] {
    return this.Contents?.map((content: IEHContent) => {
      const y = content.graphic.position.percent.y + '%'
      const x = content.graphic.position.percent.x + '%'

      const productCalculated: IProductForBtn = {
        id: content.id,
        specialSVGElementId: content.graphic.showSpecialSVGElementIdOnHoverIcon,
        customerPage: content.customerPage ?? undefined,
        icon:
          content.graphic && content.graphic.icon && content.graphic.icon.name
            ? content.graphic.icon.name
            : '',
        specialProp: content.specialProp,
        cmsPage: content.cmsPage,
        title: content.title,
        x,
        y
      }
      return productCalculated
    })
  }

  public loadFollowUpContent() {
    return new Promise((resolve) => {
      runInAction(() => {
        this.followUpContentLoaded = false
      })
      this.api.getOneContent(this.actualDetailsContentId).then((data: any) => {
        console.log('get followup Content from server', toJS(data))
        runInAction(() => {
          this.followUpContent = data[0]
        })

        setTimeout(() => {
          runInAction(() => {
            this.followUpContentLoaded = true
          })
        }, 500)
        resolve(data[0])
      })
    })
  }

  public setFollowUpContentId(id: string) {
    this.actualDetailsContentId = id
  }

  public updateSelectedProductForBtn = (newProd: IProductForBtn | undefined) => {
    // console.log(toJS(this.selectedProductForBtn), toJS(newProd))

    if (!!newProd) {
      // if there is a selected product before selection
      if (!!this.selectedProductForBtn) {
        // click on the same product
        if (newProd.id === this.selectedProductForBtn.id) {
          this.rootStore.uiStore.updateShowProductCard(false)
          this.selectedProductForBtn = undefined
          this.updateActualProductId('')
          this.updateCmsPageId('')
          console.log('1', this.actualProductId)
        } else {
          // click on another product
          this.rootStore.uiStore.updateShowProductCard(true)
          this.rootStore.uiStore.updateShowContactCard(false)
          this.selectedProductForBtn = newProd
          this.updateActualProductId(newProd.id)
          this.updateCmsPageId(newProd.cmsPage._id)
          console.log('2', this.actualProductId)
        }
      } else {
        // there is no selected product before selection
        this.selectedProductForBtn = newProd
        this.updateActualProductId(newProd.id)
        this.updateCmsPageId(newProd.cmsPage._id)
        this.rootStore.uiStore.updateShowProductCard(true)
        this.rootStore.uiStore.updateShowContactCard(false)
        console.log('3', this.actualProductId)
      }
    } else {
      // reset prod
      this.selectedProductForBtn = newProd
      this.updateActualProductId('')
      this.updateCmsPageId('')
      console.log('4', this.actualProductId)
    }

    // console.log('actual product content: ', toJS(this.actualContentId), toJS(newProd.cmsPage))
  }

  public updateActualProductModules(modules: IModule[] | undefined) {
    const sortedModules = !!modules
      ? modules.sort((a: IModule, b: IModule) => a.meta.global.order - b.meta.global.order)
      : undefined
    this.actualProductModules = sortedModules
  }

  public updateActualProductInfo(id: string) {
    if (id !== '') {
      this.Contents.map((item: any) => {
        if (item.id === id) {
          this.actualProductInfo = item
          return
        }
      })
    } else {
      this.actualProductInfo = undefined
    }
  }

  public updateActualProductId = (id: string) => {
    this.actualProductId = id
    this.updateActualProductInfo(id)
  }

  public updateCmsPageId = (id: string) => {
    // console.log('id for modules: ', id)
    this.cmsPageId = id
    if (this.actualProductId !== '' && id !== '') {
      this.getModuleContentByCmsPageId(this.cmsPageId)
    } else {
      this.getModuleContentByCmsPageId('')
    }
  }

  public updateFollowUpContent = (content: any, loaded?: boolean) => {
    this.followUpContent = content
    this.followUpContentLoaded = loaded
  }

  public loadImagesInModules = (img: { url: string }) => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image()
      loadImg.src = img.url
      loadImg.onload = () => {
        setTimeout(() => {
          resolve(img.url)
        }, 600)
      }
      loadImg.onerror = (err) => reject(err)
    })
  }
}

export default ContentStore
